import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {SubscriptionContent} from '../models/content/subscription-content.model';
import {TenantService} from './tenant.service';
import {first} from 'rxjs/operators';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class ContentDbService {

  constructor(
    private tenant:TenantService,
    private firestore: Firestore) {

  }

  loadPageContent(contentPath:string) : Observable<SubscriptionContent> {

    return docData(doc(this.firestore, `schools/${this.tenant.id}/content/${contentPath}`), {idField: 'id'})
      .pipe(
        first()
      ) as Observable<SubscriptionContent>;

  }

  savePageContent(savePath:string, newContent:any): Observable<any> {

    const savePageContentDocRef = doc(this.firestore, `schools/${this.tenant.id}/content/${savePath}`)
    const savePageContentAsync = setDoc(savePageContentDocRef, newContent)

    return from(savePageContentAsync);

  }



}

